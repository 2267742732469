/*
:root {
    --color-back-grad: #4f46e410;
}
*/

html {
  background-color: #010101;
}


body {
  /*
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='926' height='926' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='88' y1='88' x2='0' y2='0'%3E%3Cstop offset='0' stop-color='%23002a92'/%3E%3Cstop offset='1' stop-color='%23007cc4'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='75' y1='76' x2='168' y2='160'%3E%3Cstop offset='0' stop-color='%23162225'/%3E%3Cstop offset='0.09' stop-color='%231c282c'/%3E%3Cstop offset='0.18' stop-color='%23202c30'/%3E%3Cstop offset='0.31' stop-color='%23242f33'/%3E%3Cstop offset='0.44' stop-color='%23263236'/%3E%3Cstop offset='0.59' stop-color='%23283438'/%3E%3Cstop offset='0.75' stop-color='%23293539'/%3E%3Cstop offset='1' stop-color='%232A363A'/%3E%3C/linearGradient%3E%3Cfilter id='c' x='0' y='0' width='200%25' height='200%25'%3E%3CfeGaussianBlur in='SourceGraphic' stdDeviation='12' /%3E%3C/filter%3E%3C/defs%3E%3Cpolygon fill='url(%23a)' points='0 174 0 0 174 0'/%3E%3Cpath fill='%23000' fill-opacity='0.54' filter='url(%23c)' d='M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z'/%3E%3Cpath fill='url(%23b)' d='M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z'/%3E%3C/svg%3E");
background-attachment: fixed;
background-repeat: no-repeat;
background-position: top left;
*/
margin: 0px;

/*
background: radial-gradient(circle at top left,transparent 9%, #4f46e42a 10% ,#4f46e42a 15% , transparent 16%) , radial-gradient(circle at bottom left,transparent 9%, #4f46e42a 10% ,#4f46e42a 15% , transparent 16%), radial-gradient(circle at top right ,transparent 9%, #4f46e42a 10% ,#4f46e42a 15% , transparent 16%) , radial-gradient(circle at bottom right,transparent 9%, #4f46e42a 10% ,#4f46e42a 15% , transparent 16%),radial-gradient(circle, transparent 25%, #ffffff  26%),linear-gradient(45deg, transparent 46%, #4f46e42a 47%, #4f46e42a 52%, transparent 53%), linear-gradient(135deg, transparent 46%, #4f46e42a 47%, #4f46e42a 52%, transparent 53%);
        background-size: 2em 2em;
*/
}

